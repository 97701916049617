
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { mapGetters } from 'vuex'

@Component({
  components: { GAlert },
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class FileDetail extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Array, required: true }) thumbnails!: {
    src: string,
    type: string,
    text: string,
    smallUrl: string,
    largeUrl: string
  }[]

  @Prop({ type: Boolean, default: false }) open!: boolean;

  isMobile!: boolean
  selectedThumbnailIndex: number = 0;
  flag = false
  icon = 'mdi-delete'
  alert = {
    open: false,
    title: '¿Está seguro que desea eliminar este archivo?',
  }

  @Watch('flag', { immediate: true })
  onChange (newV, oldV) {
    if (!newV && oldV !== undefined) {
      this.$emit('open')
    }
  }

  @Watch('open', { immediate: true })
  onOpenChange (open) {
    this.flag = open
  }

  @Watch('thumbnails', { immediate: true })
  onThumbnailsIsEmpty (val) {
    if (!val?.length) {
      this.flag = false
    }
  }

  showAlert () {
    this.alert.open = true
  }

  confirmAction () {
    const { thumbnails, selectedThumbnailIndex } = this
    this.$emit('delete-file', thumbnails[selectedThumbnailIndex])
    this.alert.open = false
  }

  get size () {
    const { isMobile } = this

    return isMobile ? 360 : this.$vuetify.breakpoint.height - 100
  }

  openLink (link) {
    if (!link?.length) return
    if (!link.startsWith('https://')) {
      link = 'https://' + link
    }

    window.open(link, '_blank')
  }
  }
