
  import { Component, Prop } from 'vue-property-decorator'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'

@Component
  export default class FileThumbnail extends FilesProcess {
  @Prop({ type: Object, required: true }) thumbnail!: {
    src: string,
    type: string,
    text: string,
    smallUrl: string,
    largeUrl: string
  }

  @Prop({ type: String, default: '' }) text!: string
  @Prop({ type: Boolean, default: false }) multiple!: boolean;

  get srcLarge () {
    const { thumbnail } = this

    return thumbnail?.largeUrl || thumbnail?.src
  }

  get srcSmall () {
    const { thumbnail } = this

    return thumbnail?.smallUrl || thumbnail?.src
  }
  }
